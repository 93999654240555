// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import '../js/bootstrap_js_files.js'
import '@fortawesome/fontawesome-free/css/all'

// Stylesheets
import('packs/pos.scss')

require('jquery')
require("easy-autocomplete")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function () {
  $('.calc-btn').click(function (event) {
    var tendered = $('#tendered').val()
    var value = $(this).attr('value')
    var fill_total = $(this).data('total')
    var clear = $(this).data('clear')

    event.preventDefault()

    if (tendered.trim() == '0,00' || clear) {
      tendered = ''
    }
    if (value == 'del') {
      if (tendered != null || tendered != '') {
        tendered = tendered.slice(0, -1)
      }
    } else if (value == 'c') {
      tendered = ''
    } else if (fill_total == '1') {
      tendered = $(this).attr('value')
    } else {
      tendered = tendered + $(this).attr('value')
    }
    $('#tendered').val(tendered)
    recalculate_all()
  })

  $('#discount').keyup(function () {
    recalculate_all()
  })

  $('#discount-pin').keyup(function () {
    recalculate_pin()
  })

  $('#discount-coupon-pin').keyup(function () {
    recalculate_all()
  })

  $('#discount-coupon-cash').keyup(function () {
    recalculate_all()
  })

  $('input[name="discount_unit"]:visible').change(function () {
    recalculate_pin()
  })

  $('input#discount_unit_amount_pin').change(function () {
    recalculate_pin()
  })

  $('input#discount_unit_amount').change(function () {
    recalculate_all()
  })
  $('input#discount_unit_percentage_pin').change(function () {
    recalculate_pin()
  })
  $('input#discount_unit_percentage').change(function () {
    recalculate_all()
  })
  $('#pin_remainder:visible').change(function () {
    recalculate_all()
  })
  $('input[id*="discount_option_pin_"]').change(function () {
    recalculate_pin()
  })
  $('input[id*="discount_option_"]').change(function () {
    recalculate_all()
  })
  function recalculate_pin() {
    var discount = $('#discount-pin').val()
    var discount_number = parseFloat(discount.replace(',', '.'))
    var org_total = $('#total-pin').data('total')

    var formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    if (discount_number) {
      if ($('input#discount_unit_amount_pin:checked').val() == 'amount') {
        org_total -= discount_number
      }
      if ($('input#discount_unit_percentage_pin:checked').val() == 'percentage') {
        org_total -= org_total * (discount_number / 100.0)
      }
    }
    $('#total-pin').text(formatter.format(org_total))

    recalculate_all()
  }

  $('#pay').submit(function () {
    var tendered = $('#tendered').val()
    if (!(tendered && parseFloat(tendered) > 0)) {
      $('#pay-error').html('Vul een bedrag in')
      return false
    }
  })

  $('input[type=radio][name=size]').click(function () {
    $('#product-price').html($(this).data('price'))
  })

  var options = {
    url: function(phrase) {
      return "/search_shopper.json?q=" + phrase;
    },
    getValue: "name",

    template: {
      type: "custom",
      method: function(value, item) {
        return value + " (" + item.email + ")";
      }
    },
    list: {
      onChooseEvent: function() {
        $('#select-shopper').data('selected-shopper', ($("#search-shopper").getSelectedItemData()));
      },
      onShowListEvent: function() {
        $('#select-shopper').data('selected-shopper', {});
      },
	  }
  };

  var options_edit = {
    url: function(phrase) {
      return "/search_shopper.json?q=" + phrase;
    },
    getValue: "name",

    template: {
      type: "custom",
      method: function(value, item) {
        return value + " (" + item.email + ")";
      }
    },
    list: {
      onChooseEvent: function() {
        var selected_shopper = $('#search-shopper-edit').getSelectedItemData();
        $('input[name=vm_shopper_id]').val(selected_shopper.id);
        $('input[name=shopper_id]').val(selected_shopper.internal_id);
      },
      onShowListEvent: function() {
        $('#select-shopper-edit').data('selected-shopper', {});
      },
	  }
  };
  $('#search-shopper').easyAutocomplete(options);
  $('#search-shopper-edit').easyAutocomplete(options_edit);

  $('#select-shopper').click(function () {
    var selected_shopper = $(this).data('selected-shopper');
    if(selected_shopper && Object.keys(selected_shopper).length > 0) {
      $('input[name=email]').val(selected_shopper.email);
      $('input[name=vm_shopper_id]').val(selected_shopper.id);
      $('input[name=shopper_id]').val(selected_shopper.internal_id);
      $('#shopper-name').html(selected_shopper.name);
    }
  });

  $('input[name$="[line_item_discount][][discount]"]').keyup(function () {
    recalculate_all()
  })

  $('input[name$="[line_item_discount][][discount_unit]"]').change(function () {
    recalculate_all()
  })

  $('input[name$="discount_type"]').change(function () {
    $('input[name$="discount_type"]:checked').each(function() {
      var discount_type = $(this).val()

      if(!$(this).is(":visible")) {
        return
      }

      if($('#nav-cash').is(":visible")) {
        init_cash();
      }

      if($('#nav-pin').is(":visible")) {
        init_pin();
      }

      if(discount_type == 'normal') {
        clear_line_items_discount_fields()
      }
      if(discount_type == 'line_items') {
        clear_normal_discount_fields()
      }
    })
    recalculate_all()
  })

  $('button[data-bs-toggle="tab"]').on('hidden.bs.tab', function () {
    clear_normal_discount_fields()
    clear_line_items_discount_fields()
    recalculate_all()
    if($('#nav-cash').is(":visible")) {
      init_cash();
    }

    if($('#nav-pin').is(":visible")) {
      init_pin();
    }
  })

  function init_cash() {
    $('#nav-cash .line-items-discount').removeClass('show')

    if($('#nav-cash input[name="discount_type"]:checked').val() == 'normal') {
      $('#nav-cash .normal-discount').removeClass('d-none')
      $('#nav-cash .line-item-discount-button').addClass('d-none')
    }

    if($('#nav-cash input[name="discount_type"]:checked').val() == 'line_items') {
      $('#nav-cash .normal-discount').addClass('d-none')
      $('#nav-cash .line-item-discount-button').removeClass('d-none')
    }
  }

  function init_pin() {
    $('#nav-pin .line-items-discount').removeClass('show')

    if($('#nav-pin input[name="discount_type"]:checked').val() == 'normal') {
      $('#nav-pin .normal-discount').removeClass('d-none')
      $('#nav-pin .line-item-discount-button').addClass('d-none')
    }

    if($('#nav-pin input[name="discount_type"]:checked').val() == 'line_items') {
      $('#nav-pin .normal-discount').addClass('d-none')
      $('#nav-pin .line-item-discount-button').removeClass('d-none')
    }
  }

  function clear_normal_discount_fields() {
    $('input[name="discount"]').val('')
  }

  function clear_line_items_discount_fields() {
    $('input[name$="[line_item_discount][][discount]"]').val('')
  }

  function recalculate_all() {
    var total_discount = 0;
    var org_total = $('#total').data('total')
    var discount;
    var discount_number = 0;
    var unit_value;

    $('input[name$="[line_item_discount][][discount]"]:visible').each(function() {
      var id = $(this).data('line-item-id')
      discount = $(this).val()
      discount_number = parseFloat(discount.replace(',', '.'))
      var line_item_total = $(this).data('line-item-total')

      unit_value = $('input[name="line_item[' + id + '][line_item_discount][][discount_unit]"]:checked:visible').val()
      if(unit_value == 'amount' && discount_number) {
        total_discount += discount_number
      }
      if(unit_value == 'percentage' && discount_number) {
        total_discount += line_item_total * (discount_number / 100.0)
      }
      if(parseFloat(unit_value) > 0.0) {
        discount_number = parseFloat(unit_value)
        total_discount += line_item_total * (discount_number / 100.0)
      }
    })

    unit_value = $('input[name="discount_unit"]:checked:visible').val()

    discount = $('#discount').val()
    discount_number = parseFloat(discount.replace(',', '.'))

    if($('#discount_unit_amount:checked').val() == 'amount' && discount_number) {
      total_discount += discount_number
    }
    if($('#discount_unit_percentage:checked').val() == 'percentage' && discount_number) {
      total_discount += org_total * (discount_number / 100.0)
    }

    if(parseFloat(unit_value) > 0.0) {
      discount_number = parseFloat(unit_value)
      total_discount += org_total * (discount_number / 100.0)
    }

    discount = $('#discount-pin').val()
    discount_number = parseFloat(discount.replace(',', '.'))

    if($('#discount_unit_amount_pin:checked').val() == 'amount' && discount_number) {
      total_discount += discount_number
    }
    if($('#discount_unit_percentage_pin:checked').val() == 'percentage' && discount_number) {
      total_discount += org_total * (discount_number / 100.0)
    }

    var formatter = new Intl.NumberFormat('nl-NL', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    var discount_coupon = 0.0

    var discount_coupon_str = $('#discount-coupon-pin:visible').val() || $('#discount-coupon-cash:visible').val()
    if(discount_coupon_str) {
      discount_coupon_str = parseFloat(discount_coupon_str.replace(',', '.'))
      discount_coupon = parseFloat(discount_coupon_str)
      total_discount += discount_coupon
    }

    $('#total-discount').text(formatter.format(total_discount))
    $('#total-discount-pin').text(formatter.format(total_discount))
    $('#total').text(formatter.format(org_total - total_discount))
    $('#total-pin').text(formatter.format(org_total - total_discount))

    var total_number = org_total - total_discount
    var tendered = $('#tendered').val()
    var change_decimal = 0.0

    if (total_number && tendered) {
      total_number = Math.round(total_number * 100) / 100
      var tendered_decimal = Math.round(parseFloat(tendered) * 100) / 100
      if($('#pin_remainder:visible') && tendered_decimal < total_number) {
        $('#pin_remainder').removeAttr('disabled');
      } else {
        $('#pin_remainder').attr('disabled', true);
      }
      if(!$('#pin_remainder:visible').is(':checked')) {
        change_decimal = tendered_decimal - total_number
      }
    }
    $('#change').text(formatter.format(change_decimal))
  }
})
